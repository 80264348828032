@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Bambino";
  src: url("./assets/fonts/Bambino.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bambino";
  src: url("./assets/fonts/Bambino Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Bambino";
  src: url("./assets/fonts/Bambino Black Italic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Bambino";
  src: url("./assets/fonts/Bambino Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Bambino";
  src: url("./assets/fonts/Bambino Bold Italic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Bambino";
  src: url("./assets/fonts/Bambino Extra Light.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Bambino";
  src: url("./assets/fonts/Bambino Extra Light Italic.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Bambino";
  src: url("./assets/fonts/Bambino Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Bambino";
  src: url("./assets/fonts/Bambino Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Bambino";
  src: url("./assets/fonts/Bambino Light Italic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Bambino";
  src: url("./assets/fonts/Bambino Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Bambino";
  src: url("./assets/fonts/Bambino Thin Italic.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
